import dynamic from "next/dynamic";

import useHashScroll from "../hooks/useHashScroll";
import usePageRedirect from "../hooks/usePageRedirect";
import {
  getComponentForMagnoliaTemplate,
  magnoliaEditablePageConfig
} from "../magnolia.config";
import buildGetStaticProps from "../service/static-props-api";
import { WifStaticProps } from "../types/wif-next";
import { NodeWithIsPreview } from "@/util/SmartEditableArea";

const EditablePage = dynamic(
  () => import("../components/MgnlEditor/EditablePage")
);

const getStaticPaths = async () => {
  return { paths: [], fallback: "blocking" };
};

export { getStaticPaths };
export const getStaticProps = buildGetStaticProps();

export default function Path(props: WifStaticProps) {
  const { page, templateAnnotations, isPreview } = props;
  const redirectPage = page?.redirectPage ?? null;
  const redirectAnchorTag = page?.redirectAnchorTag ?? null;

  usePageRedirect(redirectPage, redirectAnchorTag);
  useHashScroll(!!redirectPage);

  if (!page) {
    return null;
  }

  if (isPreview) {
    return (
      <EditablePage
        content={page}
        config={{
          ...magnoliaEditablePageConfig,
          componentMappings: Object.entries(magnoliaEditablePageConfig.componentMappings)
            .reduce((acc, [key, Component]) => {
              acc[key] = (props: any) => {
                return <Component isPreview={isPreview} {...props} />;
              };
              return acc;
            }, {} as Record<string, any>)
        }}
        templateAnnotations={templateAnnotations}
      />
    );
  }

  return <CustomPageRenderer {...page} />;
}

function CustomPageRenderer(page: NodeWithIsPreview<Wif.Mgnl.Page>) {
  const PageComponent = getComponentForMagnoliaTemplate(page["mgnl:template"]);

  if (!PageComponent) return null;

  return (
    <PageComponent key={page["@id"]} {...page} />
  );
}
